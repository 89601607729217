"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardStatuses = exports.DropdownValue = void 0;
var DropdownValue;
(function (DropdownValue) {
    DropdownValue[DropdownValue["A_Z"] = 0] = "A_Z";
    DropdownValue[DropdownValue["Z_A"] = 1] = "Z_A";
    DropdownValue[DropdownValue["FROM_OLD"] = 2] = "FROM_OLD";
    DropdownValue[DropdownValue["FROM_NEW"] = 3] = "FROM_NEW";
})(DropdownValue = exports.DropdownValue || (exports.DropdownValue = {}));
var CardStatuses;
(function (CardStatuses) {
    CardStatuses[CardStatuses["ERROR_REMOVE_FROM_CARD"] = 0] = "ERROR_REMOVE_FROM_CARD";
    CardStatuses[CardStatuses["CAN_ADD"] = 1] = "CAN_ADD";
    CardStatuses[CardStatuses["ALREADY_EXISTS"] = 2] = "ALREADY_EXISTS";
    CardStatuses[CardStatuses["TOO_MANY"] = 3] = "TOO_MANY";
    CardStatuses[CardStatuses["ADDED_SUCCESSFULLY"] = 4] = "ADDED_SUCCESSFULLY";
    CardStatuses[CardStatuses["REMOVED_SUCCESSFULLY"] = 5] = "REMOVED_SUCCESSFULLY";
})(CardStatuses = exports.CardStatuses || (exports.CardStatuses = {}));
