"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
const AppController_1 = require("./controller/AppController");
const AppModel_1 = require("./model/AppModel");
const AppView_1 = require("./view/AppView");
class App {
    constructor() {
        this.model = new AppModel_1.AppModel();
        this.view = new AppView_1.AppView();
        this.controller = new AppController_1.AppController(this.model, this.view);
    }
    start() {
        this.controller.init();
    }
}
exports.App = App;
