"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppController = void 0;
class AppController {
    constructor(model, view) {
        this.appModel = model;
        this.appView = view;
        this.appView.setController(this);
    }
    init() {
        this.appView.createInitialView();
        this.appModel.loadSavedData();
        this.getStoreData();
    }
    getStoreData() {
        this.appModel.getAllStore((data) => {
            console.log(data);
            this.appView.createInitialFilters(this.appModel.makeFilters, this.appModel.allPrices);
        });
    }
    handleFilterChanged(filter) {
        this.appModel.updateFilterState(filter);
        this.appView.showFilteredCards(this.appModel.updateFilteredData());
    }
    handleClearLocalStorage() {
        this.appModel.clearLocalStorage();
    }
    saveVisualSort(filterVal) {
        this.appModel.saveVisualSort(filterVal);
    }
    addToCard(product) {
        return this.appModel.addToCard(product.id);
    }
    removeFromCard(product) {
        return this.appModel.removeFromCard(product.id);
    }
    getCard() {
        return this.appModel.onCard;
    }
}
exports.AppController = AppController;
