"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalCardError = exports.buttonTemplate = exports.dropdownSortActions = exports.searchInput = exports.productTemplate = exports.checkboxTemplate = exports.sliderTemplate = exports.subfilterTemplate = exports.filterTemplate = exports.bufferDiv = exports.divTemplate = exports.footerTemplate = exports.previewTemplate = exports.mainTemplate = exports.headerTemplate = void 0;
function headerTemplate(visualName) {
    visualName;
    return `<header>
                <div class="main-header-wrapper">
                    <div class="main-header">
                        <div class="name-header">
                            <i class="fa header-envelope"></i>
                            Минск
                        </div>
                        <div class="working-hours-header">
                            <i class="fa header-clock"></i>
                            Без выходных 9:00 - 20:00
                        </div>
                        <div class="contacts-header">
                            <i class="fa header-phone"></i>
                            <a href="tel: +375336028529">+375 (33) 602-85-29</a>
                            <a href="tel: +375296365003">+ 375 (29) 636-50-03</a>
                        </div>
                        <div class="contacts-header-mobile">
                            <i class="fa header-phone"></i>
                            <div class="contacts-header-mobile-contacts">
                                <a href="tel: +375336028529">+375 (33) 602-85-29</a>
                                <a href="tel: +375296365003">+ 375 (29) 636-50-03</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breaker-header"></div>
                <div class="under-header">
                    <div class="logo-under-header">
                        <image src="./assets/img/logo1.jpg" alt="Бумажные стаканчики"></image>
                    </div>
                    <div class="disclamer-under-header">
                        <p>Продажа бумажных стаканчиков, крышек и мешалок для кофе автоматов, кофеен и личного пользования</p>
                        <span class="color-orange"><b>Бесплатная доставка</b></span>
                    </div>
                </div>
            </header>`;
}
exports.headerTemplate = headerTemplate;
function mainTemplate() {
    return `<main></main>`;
}
exports.mainTemplate = mainTemplate;
function previewTemplate(product) {
    return `<div class="preview-class">
                <div class="preview-class-main">
                    <img class="preview-cover-image" src="${product.img}" alt="${product.model}">
                    <div class="preview-class__text-content">
                        <h1 class="preview-class__h1">${product.model}</h1>
                        <p id="previewP1">${product.description}</p>
                        <br>
                        <p id="previewPrice">${product.price} руб.</p>
                    </div>
                </div>
                <div class="preview-class-table" style="display: ${product.measures ? '' : 'none'}">
                    <div class="preview-product-prop">
                        <img class="preview-product-prop-img" src="./assets/img/prop_table.jpg" alt="таблица"></img>
                        <div>
                            <table align="left" border="1" cellpadding="1" cellspacing="1" style="width:400px">
                                <tbody>
                                    <tr>
                                        <td class="rtecenter" style="width:75px">${product.measures ? product.measures[0] : '0'}</td>
                                        <td class="rtecenter" style="width:75px">${product.measures ? product.measures[1] : '0'}</td>
                                        <td class="rtecenter" style="width:73px">${product.measures ? product.measures[2] : '0'}</td>
                                        <td class="rtecenter" style="width:75px">${product.measures ? product.measures[3] : '0'}</td>
                                        <td class="rtecenter" style="width:74px">${product.measures ? product.measures[4] : '0'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>`;
}
exports.previewTemplate = previewTemplate;
function footerTemplate() {
    return `<footer>
                <div class="footer-main">
                    <div class="footer-main-info">
                        <h2 class="footer-title">Контакты</h2>
                        <ul class="address-info">
                            <li>
                                <i class="fa header-phone"></i> <a href="tel:+375336028529">+ 375 (33) 602-85-29</a>
                            </li>
                            <li>
                                <i class="fa header-phone"></i> <a href="tel:+375296365003">+ 375 (29) 636-50-03</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-main-info">
                        <h2 class="footer-title">Информация</h2>
                        <p>ИП Сергачёв А. В.</p>
                        <p>УНП 192037492</p>
                        <p>220055 г. Минск, ул. Налибокская, д. 32</p>
                    </div>
                </div>
                <div class="footer-copyright">
                    <div class="copyright-law">
                        <p>Предстваленная инфаормация не является договором открытой оферты.</p>
                        <p>Информация размещается для ознакомления с целью последующего оформления договора оптовой торговли.</p>
                    </div>
                    <div class="copyright">© ${new Date().getFullYear()}</div>
                </div>
            </footer>`;
}
exports.footerTemplate = footerTemplate;
function divTemplate(styleClassName) {
    return `<div class="${styleClassName}"></div>`;
}
exports.divTemplate = divTemplate;
function bufferDiv(styleClassName) {
    return `<div class="${styleClassName}">
                Гибкая система скидок! Доставка бумажных стаканов по Минску - БЕСПЛАТНО. Доставка в регионы.
            </div>`;
}
exports.bufferDiv = bufferDiv;
function filterTemplate(styleClassName, visualName) {
    return `<div class="${styleClassName}">
                <h4>${visualName}</h4>
            </div>`;
}
exports.filterTemplate = filterTemplate;
function subfilterTemplate(styleClassName, visualName) {
    return `<div class="${styleClassName}">
                <h5>${visualName}</h5>
            </div>`;
}
exports.subfilterTemplate = subfilterTemplate;
function sliderTemplate(containerClassName, sliderIdName) {
    return `<div class="${containerClassName}">
                <div id="${sliderIdName}"></div>
            </div>`;
}
exports.sliderTemplate = sliderTemplate;
function checkboxTemplate(value) {
    return `<div class="form-check">
                <input class="form-check-input" type="checkbox" value="${value}" id="${value}">
                <label class="form-check-label" for="${value}">
                    ${value}
                </label>
            </div>`;
}
exports.checkboxTemplate = checkboxTemplate;
function productTemplate(product) {
    return `<div class="product">
                <img id="cover-image" src="${product.img}" alt="${product.model}">
                <div class="overview">
                    <div class="product-info">
                        <h3 class="main-title">${product.price.toString()} руб. (упаковка)</h3>
                    </div> 
                    <div class="product-name font-bold">
                        <p>${product.model}</p>
                    </div> 
                    <div class="overview2">
                        ${product.description}
                    </div> 
                </div> 
                <div id="markOnCard">on card</div>
            </div>`;
}
exports.productTemplate = productTemplate;
function searchInput() {
    return `<form id="form">
                <div class="input-clear">
                    <img src="./assets/img/close_icon.svg" alt="close" class="search-close-img">
                </div>
                <input type="text" id="search" class="search" placeholder="Поиск" autocomplete="off">
            </form>`;
}
exports.searchInput = searchInput;
function dropdownSortActions() {
    return `<div class="dropdown">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                    aria-expanded="true">
                    Cортировка
                </a>
            
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li value="0"><a class="dropdown-item" href="#">A-Z</a></li>
                    <li value="1"><a class="dropdown-item" href="#">Z-A</a></li>
                    <li value="2"><a class="dropdown-item" href="#">from Old</a></li>
                    <li value="3"><a class="dropdown-item" href="#">from New</a></li>
                </ul>
            </div>`;
}
exports.dropdownSortActions = dropdownSortActions;
function buttonTemplate(name) {
    return `<button type="button" class="btn btn-outline-primary">${name}</button>`;
}
exports.buttonTemplate = buttonTemplate;
function modalCardError(count) {
    return `<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Card Warning</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        You can have only ${count.toString()} items on Card at the same time!
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>`;
}
exports.modalCardError = modalCardError;
